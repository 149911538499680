@import url('https://fonts.googleapis.com/css?family=Josefin+Sans|Roboto:400,500&display=swap');

$backgroundColor: #ccc;

body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
}

h1,
h2,
h3 {
  font-family: 'Josefin Sans';
}

@import 'landing';
