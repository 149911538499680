@mixin absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.logo {
  background: url(../images/logo-h.svg) no-repeat center center scroll;
  color: transparent;
  display: block;
  height: 32px;
  width: 150px;
  margin: 9px auto 16px;
  background-size: contain;
}

.button {
  background-color: #00fff0;
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.21em;
  text-transform: uppercase;
  display: block;
  padding: 16px 24px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
}

.room {
  position: relative;
  color: #fff;
  margin-bottom: 272px;
  min-height: calc(100vh - 57px);

  .wall {
    background: url(../images/wall.jpg) repeat center center #555;
    width: 240px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    overflow: hidden;
    background-blend-mode: overlay;

    @media screen and (min-width: 1280px) {
      width: 440px;
    }

    &::before {
      content: '';
      position: absolute;
      top: -100%;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      z-index: 1220;
      mix-blend-mode: soft-light;
      display: block;
      box-shadow: 0 calc(100vh - 57px) 0 rgba(0, 0, 0, 0.9);
    }

    .control {
      width: 200px;
      height: 320px;
      z-index: 900;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: url(../images/metal.jpg) no-repeat center center #555;
      box-shadow: -1px 3px 14px rgba(0, 0, 0, 0.2), inset 0px -1px 2px rgba(0, 0, 0, 0.35),
        inset -1px 0px 2px rgba(255, 255, 255, 0.5);
      border-radius: 2px;
      position: relative;
      background-blend-mode: overlay;

      &::before {
        content: '';
        position: absolute;
        top: 20px;
        left: 20px;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        background: #333;
        display: block;
        border: 2px solid #000;
        box-sizing: border-box;
      }

      .switch {
        height: 60px;
        width: 120px;
        background-color: transparent;
        margin-bottom: 20px;
        border-radius: 4px;
        position: relative;
        cursor: pointer;
        border: 2px solid #2d9cdb;

        &::before {
          content: '';
          height: 100%;
          width: calc(100% - 30px);
          display: block;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
          background-color: #fff;
          transition: transform 0.07s ease;
          border-radius: 2px;
        }

        &.on {
          background-color: #2d9cdb;

          &::before {
            transform: translateX(30px);
          }
        }
      }
    }
  }

  .lights {
    @include absolute();
    z-index: 0;
    background-image: url('../images/living-infinity-oscuro.jpg');
    background-position: right center;
    background-size: cover;
    background-repeat: no-repeat;

    .light {
      // transition: opacity 0.4s ease;
      opacity: 0;

      &.on {
        animation: turnOnLight 0.4s ease;
        animation-fill-mode: forwards;
      }
    }

    .spot {
      @include absolute();
      z-index: 3;
      background-image: url('../images/living-infinity-spot2.jpg');
      background-position: right center;
      background-size: cover;
      background-repeat: no-repeat;
      mix-blend-mode: color-dodge;
    }
    .ambient {
      @include absolute();
      z-index: 2;
      background-image: url('../images/living-infinity-ambient.jpg');
      background-position: right center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  &.ambient-on {
    .wall {
      background-blend-mode: normal;

      &::before {
        display: none;
      }

      .control {
        background-blend-mode: normal;

        &::before {
          background-color: #e5e5e5;
        }
        .switch {
          background-color: #c4c4c4;
          border: 2px solid transparent;

          &.on {
            background-color: #2d9cdb;
          }
        }
      }
    }

    .lights {
      .light.spot {
        mix-blend-mode: overlay;
      }
    }
  }

  .cta {
    position: absolute;
    width: calc(100vw - 240px - 24px);
    right: 24px;
    top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media screen and (min-width: 1280px) {
      right: 32px;
      top: 32px;
      width: calc(100vw - 440px - 64px);
    }

    h1 {
      font-family: 'Josefin Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      line-height: 1.4;
      text-align: right;
      letter-spacing: 0.21em;
      text-transform: uppercase;
      margin-bottom: 16px;
    }
    h2 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.21em;
      text-transform: uppercase;
      margin-bottom: 16px;
    }
  }
}

@keyframes turnOnLight {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes turnOffLight {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.wrapper {
  max-width: 800px;
  width: 100%;
  margin: 0 auto 0;

  &.margin-bottom {
    margin-bottom: 40px;
  }
}

.section {
  margin-bottom: 272px;

  h2 {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 1.2;
    letter-spacing: 0.21em;
    text-transform: uppercase;
    width: 50%;
    margin-bottom: 24px;
  }

  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 24px;
  }

  .strong {
    font-weight: 500;
  }

  &.center {
    text-align: center;
    h2 {
      width: auto;
    }
  }

  .image-center {
    display: flex;
    justify-content: center;
  }
}

.slider {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  .slide {
    margin: 0 20px 20px;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      max-width: 320px;
    }
  }
}

form {
  width: 300px;
  margin: 0 auto;

  .input {
    margin-bottom: 8px;
    text-align: left;

    label {
      display: block;
      text-align: left;
    }

    input {
      display: block;
      width: 100%;
      height: 32px;
      border: 1px solid #ccc;
      background: #fff;
      font-size: 14px;

      &:focus {
        outline: none;
        border: 1px solid #2d9cdb;
      }

      &[type='checkbox'] {
        display: inline;
        width: auto;
        height: auto;
        margin-right: 4px;

        & + label {
          display: inline;
        }
      }
    }
  }
}

.footer {
  text-align: center;
  padding: 32px;
  background: #fff;
}
