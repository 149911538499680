@import url(https://fonts.googleapis.com/css?family=Josefin+Sans|Roboto:400,500&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9; }

h1,
h2,
h3 {
  font-family: 'Josefin Sans'; }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

.logo {
  background: url(/static/media/logo-h.28f7172d.svg) no-repeat center center scroll;
  color: transparent;
  display: block;
  height: 32px;
  width: 150px;
  margin: 9px auto 16px;
  background-size: contain; }

.button {
  background-color: #00fff0;
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.21em;
  text-transform: uppercase;
  display: block;
  padding: 16px 24px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000; }

.room {
  position: relative;
  color: #fff;
  margin-bottom: 272px;
  min-height: calc(100vh - 57px); }
  .room .wall {
    background: url(/static/media/wall.990be81c.jpg) repeat center center #555;
    width: 240px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    overflow: hidden;
    background-blend-mode: overlay; }
    @media screen and (min-width: 1280px) {
      .room .wall {
        width: 440px; } }
    .room .wall::before {
      content: '';
      position: absolute;
      top: -100%;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      z-index: 1220;
      mix-blend-mode: soft-light;
      display: block;
      box-shadow: 0 calc(100vh - 57px) 0 rgba(0, 0, 0, 0.9); }
    .room .wall .control {
      width: 200px;
      height: 320px;
      z-index: 900;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: url(/static/media/metal.bddfe616.jpg) no-repeat center center #555;
      box-shadow: -1px 3px 14px rgba(0, 0, 0, 0.2), inset 0px -1px 2px rgba(0, 0, 0, 0.35), inset -1px 0px 2px rgba(255, 255, 255, 0.5);
      border-radius: 2px;
      position: relative;
      background-blend-mode: overlay; }
      .room .wall .control::before {
        content: '';
        position: absolute;
        top: 20px;
        left: 20px;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        background: #333;
        display: block;
        border: 2px solid #000;
        box-sizing: border-box; }
      .room .wall .control .switch {
        height: 60px;
        width: 120px;
        background-color: transparent;
        margin-bottom: 20px;
        border-radius: 4px;
        position: relative;
        cursor: pointer;
        border: 2px solid #2d9cdb; }
        .room .wall .control .switch::before {
          content: '';
          height: 100%;
          width: calc(100% - 30px);
          display: block;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
          background-color: #fff;
          -webkit-transition: -webkit-transform 0.07s ease;
          transition: -webkit-transform 0.07s ease;
          transition: transform 0.07s ease;
          transition: transform 0.07s ease, -webkit-transform 0.07s ease;
          border-radius: 2px; }
        .room .wall .control .switch.on {
          background-color: #2d9cdb; }
          .room .wall .control .switch.on::before {
            -webkit-transform: translateX(30px);
                    transform: translateX(30px); }
  .room .lights {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 0;
    background-image: url(/static/media/living-infinity-oscuro.f9679cc6.jpg);
    background-position: right center;
    background-size: cover;
    background-repeat: no-repeat; }
    .room .lights .light {
      opacity: 0; }
      .room .lights .light.on {
        -webkit-animation: turnOnLight 0.4s ease;
                animation: turnOnLight 0.4s ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
    .room .lights .spot {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      z-index: 3;
      background-image: url(/static/media/living-infinity-spot2.2ae13d24.jpg);
      background-position: right center;
      background-size: cover;
      background-repeat: no-repeat;
      mix-blend-mode: color-dodge; }
    .room .lights .ambient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      z-index: 2;
      background-image: url(/static/media/living-infinity-ambient.84eb4e9d.jpg);
      background-position: right center;
      background-size: cover;
      background-repeat: no-repeat; }
  .room.ambient-on .wall {
    background-blend-mode: normal; }
    .room.ambient-on .wall::before {
      display: none; }
    .room.ambient-on .wall .control {
      background-blend-mode: normal; }
      .room.ambient-on .wall .control::before {
        background-color: #e5e5e5; }
      .room.ambient-on .wall .control .switch {
        background-color: #c4c4c4;
        border: 2px solid transparent; }
        .room.ambient-on .wall .control .switch.on {
          background-color: #2d9cdb; }
  .room.ambient-on .lights .light.spot {
    mix-blend-mode: overlay; }
  .room .cta {
    position: absolute;
    width: calc(100vw - 240px - 24px);
    right: 24px;
    top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    @media screen and (min-width: 1280px) {
      .room .cta {
        right: 32px;
        top: 32px;
        width: calc(100vw - 440px - 64px); } }
    .room .cta h1 {
      font-family: 'Josefin Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      line-height: 1.4;
      text-align: right;
      letter-spacing: 0.21em;
      text-transform: uppercase;
      margin-bottom: 16px; }
    .room .cta h2 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.21em;
      text-transform: uppercase;
      margin-bottom: 16px; }

@-webkit-keyframes turnOnLight {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes turnOnLight {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes turnOffLight {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes turnOffLight {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.wrapper {
  max-width: 800px;
  width: 100%;
  margin: 0 auto 0; }
  .wrapper.margin-bottom {
    margin-bottom: 40px; }

.section {
  margin-bottom: 272px; }
  .section h2 {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 1.2;
    letter-spacing: 0.21em;
    text-transform: uppercase;
    width: 50%;
    margin-bottom: 24px; }
  .section p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 24px; }
  .section .strong {
    font-weight: 500; }
  .section.center {
    text-align: center; }
    .section.center h2 {
      width: auto; }
  .section .image-center {
    display: flex;
    justify-content: center; }

.slider {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center; }
  .slider .slide {
    margin: 0 20px 20px;
    display: flex;
    justify-content: center; }
    .slider .slide img {
      width: 100%;
      max-width: 320px; }

form {
  width: 300px;
  margin: 0 auto; }
  form .input {
    margin-bottom: 8px;
    text-align: left; }
    form .input label {
      display: block;
      text-align: left; }
    form .input input {
      display: block;
      width: 100%;
      height: 32px;
      border: 1px solid #ccc;
      background: #fff;
      font-size: 14px; }
      form .input input:focus {
        outline: none;
        border: 1px solid #2d9cdb; }
      form .input input[type='checkbox'] {
        display: inline;
        width: auto;
        height: auto;
        margin-right: 4px; }
        form .input input[type='checkbox'] + label {
          display: inline; }

.footer {
  text-align: center;
  padding: 32px;
  background: #fff; }

